// src/axios.js
import axios from 'axios';

// 创建一个axios实例
const axiosInstance = axios.create({
  baseURL: 'https://yidong66.icu/juejin-books-api', // 替换为你的API的基础URL
  timeout: 10000 // 设置请求超时时间
});

const tokenKey = 'juejin-books-token';

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，例如在请求头中添加token
    const token = localStorage.getItem(tokenKey);
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      // 清除token并引导用户重新登录
      localStorage.removeItem(tokenKey);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { tokenKey };