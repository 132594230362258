import Vue from 'vue'
import VueRouter from 'vue-router'
import tokenKey from '../services/axios'
import axiosInstance from '../services/axios'
import { Message } from 'element-ui';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/book',
    // component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/book/:id',
    name: 'BookDetail',
    component: () => import('../components/BookDetail.vue')
  },
  {
    path: '/book/:id/chapter/:chapterId',
    name: 'ChapterDetail',
    component: () => import('../components/ChapterDetail.vue')
  },
  {
    path: '/book',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/juejin',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next();
    return;
  }
  // 获取认证状态
  const token = localStorage.getItem(tokenKey);
  if (token === null || token === 'undefined') {
    Message.error({
      message: '请先登录',
      duration: 2500,
    });
    next({
      name: 'login'
    });
    return;
  }
  axiosInstance.post('/login/check?token=' + token).then(res => {
    console.log('登录时间：' + res.data);
    next();
  }).catch(err => {
    console.error(err);
    Message.error({
      message: '登录失效，请重新登录',
      duration: 2500,
    });
    next({
      name: 'login'
    });
  });
});

export default router